import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './components/styles/Global';
import Home from './components/Home';
import ErrorPage from './components/ErrorPage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { AppContainer, RouteContainer } from './components/styles/Container.styled';
import Input from './components/input/Input';
import HoldingInformation from './components/input/HoldingInformation';
import LandUse from './components/input/landUse/LandUse';
import HorseManureManagement from './components/input/HorseManureManagement';
import HorsesOnSite from './components/input/HorsesOnSite';
import ForagesAndStrawBedding from './components/input/purchasedFeedAndBedding/ForagesAndStrawBedding';
import SupplementaryFeeds from './components/input/purchasedFeedAndBedding/SupplementaryFeeds';
import OtherBeddingTypes from './components/input/purchasedFeedAndBedding/OtherBeddingTypes';
import Electricity from './components/input/electricityAndFuel/Electricity';
import Fuel from './components/input/electricityAndFuel/Fuel';
import ContractorsFuel from './components/input/electricityAndFuel/ContractorsFuel';
import PurchasedFuel from './components/input/electricityAndFuel/PurchasedFuel';
import HorseTransport from './components/input/electricityAndFuel/HorseTransport';
import OtherLivestock from './components/input/OtherLivestock';
import Header from './components/layout/Header';
import Login from './components/user/Login';
import Register from './components/user/Register';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import { useAppDispatch, useAppSelector } from './hooks';
import { loadUser } from './features/auth/authSlice';
import HoldingsList from './components/footprint/HoldingsList';
import NewHolding from './components/footprint/NewHolding';
import Footprints from './components/footprint/Footprints';
import UpdateHolding from './components/footprint/UpdateHolding';
import NewFootprint from './components/footprint/NewFootprint';
import UpdateFootprint from './components/footprint/UpdateFootprint';
import Results from './components/Results';
import ConfirmEmail from './components/user/ConfirmEmail';
import Footer from './components/layout/Footer';
import Users from './components/admin/Users';
import Profile from './components/user/Profile';
import AdminHoldingsList from './components/footprint/AdminHoldingsList';
import DuplicateFootprint from './components/footprint/DuplicateFootprint';
import Summary from './components/admin/Summary';
import Benchmarking from './components/Benchmarking';
import './App.css'

const theme = {
  colours: {
    mainDark: '#1b2d54'
  }

}

function App() {
  const dispatch = useAppDispatch();

  const { isAuthenticated } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(loadUser());

  }, [dispatch])
  return (

    <Router>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppContainer>
          <Header />
          <RouteContainer id='app-container'>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/admin/holdings" element={isAuthenticated ? (<AdminHoldingsList />) : (<Navigate replace to={"/login"} />)} />
              <Route path="/holdings" element={isAuthenticated ? (<HoldingsList />) : (<Navigate replace to={"/login"} />)} />
              <Route path="/holdings/:id" element={isAuthenticated ? (<HoldingsList />) : (<Navigate replace to={"/login"} />)} />
              <Route path='/holding/new' element={<NewHolding />} />
              <Route path='/holding/:id' element={<UpdateHolding />} />
              <Route path='/holdings/footprints/:id' element={isAuthenticated ? (<Footprints />) : (<Navigate replace to={"/login"} />)} />
              <Route path='/holdings/footprint/new/:id' element={<NewFootprint />} />
              <Route path='/holdings/footprint/:id' element={<UpdateFootprint />} />
              <Route path='/holdings/footprint/:id/duplicate' element={<DuplicateFootprint />} />
              <Route path="/admin/users" element={isAuthenticated ? (<Users />) : (<Navigate replace to={"/login"} />)} />
              <Route path="/admin/summary" element={isAuthenticated ? (<Summary />) : (<Navigate replace to={"/login"} />)} />
              <Route path="/user/profile" element={isAuthenticated ? (<Profile />) : (<Navigate replace to={"/login"} />)} />

              <Route path='/input' element={isAuthenticated ? (<Input />) : (<Navigate replace to={"/login"} />)}>
                <Route path='holdingInfo' element={<HoldingInformation />} />
                <Route path='landUse' element={<LandUse />} />
                <Route path='manureManagement' element={<HorseManureManagement />} />
                <Route path='horses' element={<HorsesOnSite />} />
                <Route path='purchasedFeedForages' element={<ForagesAndStrawBedding />} />
                <Route path='purchasedSupplementaryFeeds' element={<SupplementaryFeeds />} />
                <Route path='purchasedBeddingTypes' element={<OtherBeddingTypes />} />
                <Route path='electricity' element={<Electricity />} />
                <Route path='fuel' element={<Fuel />} />
                <Route path='contractorsFuel' element={<ContractorsFuel />} />
                <Route path='purchasedFuel' element={<PurchasedFuel />} />
                <Route path='horseTransport' element={<HorseTransport />} />
                <Route path='otherLivestock' element={<OtherLivestock />} />
              </Route>

              <Route path='/benchmarking' element={<Benchmarking />} />
              <Route path='/results' element={<Results />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>

          </RouteContainer>
          <Footer />
        </AppContainer>

      </ThemeProvider>
    </Router>

  );
}

export default App;
